import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import "../css/sobre-nos.css"

class About extends React.Component {
  render() {
    return (
      <>
        <Metatags
          title="Maxipack"
          description="Ampla área produtiva e de armazenagem, 2 docas cobertas para recepção e expedição de caminhões e carretas, Equipamentos atualizados, Área produtiva isolada e com aprovação de orgãos competentes como Anvisa, prefeitura e bombeiros."
          url="https://www.maxipack.com.br/sobre-nos/"
          image="https://www.maxipack.com.br/maxipack.png"
          keywords="Ampla área produtiva, armazenagem, docas, equipamentos atualizados, arear produtiva isolada, anvisa"
        />
        <Navbar />

        <Container>
          <Row>
            <Col>
              <img
                className="d-block w-100"
                src={require("../images/Testeira_mobile.png")}
                alt="Quem somos"
              />
            </Col>
          </Row>
          <div style={{ height: "40px" }} />
          <Row>
            <Col>
              <div style={{ fontSize: "1.2rem", padding: "56px 0 30px 0" }}>
                <div style={{ textAlign: "center" }}>
                  <p>
                    <b>
                      AVISE-NOS SOBRE ASSUNTOS DE CONDUTA ÉTICA, COMPLIANCE E
                      INTEGRIDADE
                    </b>
                  </p>
                </div>
                <p>
                  Os Princípios de Gestão Empresarial da Maxi Pack refletem
                  nosso compromisso de uma cultura de total conformidade e com
                  uma fundação sólida, que conduzem e orientam a forma como
                  fazemos negócios. Mais do que palavras em papel, nossos
                  princípios estão vivos e presentes em nosso dia a dia e
                  estamos preparados para fazer o que for necessário para
                  garantir que nossa empresa seja administrada de acordo com
                  nossos princípios e compromissos.
                </p>
                <p>
                  Diante disso, a empresa implantou um sistema de comunicação
                  chamado Jogo Limpo, o qual possibilita que qualquer pessoa
                  possa nos informar acerca de situações que estejam em
                  desacordo com nossos Princípios de Gestão Empresarial e/ou com
                  o Código Maxi Pack de Conduta Empresarial e, portanto, que
                  coloquem em risco a reputação e a integridade da empresa.
                </p>
                <p>
                  O Jogo Limpo é gerenciado de modo a garantir o anonimato dos
                  denunciantes e denunciados.
                </p>
                <p>
                  <b>Você pode nos ajudar a alcançar esse objetivo!</b>
                </p>
                <p>
                  Caso você queira reportar à Maxi Pack algo relacionado à
                  comportamento ético, Compliance, conduta, integridade e/ou que
                  viole nossos princípios, entre em contato:
                </p>
                
                <p>Para mensagens escritas, entre em:</p>
                <div style={{textAlign:"center"}}>
                <p><a href="https://canaldecondutaetica.maxipack.com.br/" rel="noopener noreferrer">https://canaldecondutaetica.maxipack.com.br/</a></p>
                </div>
                <p>
                  O Canal é gratuito, anônimo e está disponível a qualquer hora.
                </p>
                <p>
                  <b>Como Funciona?</b>
                </p>
                <p>
                  Acesse o canal disponibilizado no site, informado no link
                  acima. Após o envio da sua mensagem (você pode escolher se
                  identificar ou não).
                </p>
                <div style={{ textAlign: "center" }}>
                  <p>
                    <b>
                      AVISE-NOS SOBRE ASSUNTOS DE CONDUTA ÉTICA, COMPLIANCE E
                      INTEGRIDADE
                    </b>
                  </p>
                </div>
                <p>
                  O canal de comunicação foi desenvolvido para conhecermos e
                  solucionarmos situações que estejam em desacordo com nossos
                  Princípios de Gestão Empresarial e/ou com o Código Maxi Pack
                  de Conduta Empresarial e, portanto, que coloquem em risco a
                  reputação e a integridade da empresa.
                </p>
              </div>
            </Col>
          </Row>

          <div style={{ height: "40px" }} />
        </Container>

        <Footer />
      </>
    )
  }
}

export default About
